import React, { useMemo } from 'react';
import {
  useTable,
  useGlobalFilter,
  useFilters,
  Column,
  Row,
  HeaderGroup,
  ColumnInstance,
} from 'react-table';
import { useDrag } from 'react-dnd';
import { FaLink, FaUnlink, FaUserShield, FaUser } from 'react-icons/fa';

import i18n from '../i18n';
import { GlobalFilter } from './Tableutils/filters';
import styles from './styles/Table.module.css';
import buttonStyles from './styles/AssociateButton.module.css';

// Define the row data interface
export interface RowData {
  id: string;
  associated: boolean;
  isAdmin: boolean;
  ref?: string;
  status?: string;
  serial?: string;
}

const DRAG_TYPE = 'UNASSOCIATED_ROW';

// DraggableRow subcomponent
interface DraggableRowProps {
  row: Row<RowData>;
  cells: React.ReactNode[];
  onDragEnd: (id: string) => void; // Callback for drag completion
}

const DraggableRow: React.FC<DraggableRowProps> = ({ row, cells, onDragEnd }) => {
  const canDrag = row.original.id === 'UNASSOCIATED';

  const [{ isDragging }, dragRef] = useDrag({
    type: DRAG_TYPE,
    item: {
      id: row.original.id,
      ref: row.original?.ref,
      serial: row.original?.serial,
    },
    canDrag,
    end: (item, monitor) => {
      if (monitor.didDrop() && item) {
        onDragEnd(item.id);
      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const rowClass = `
    ${styles.tableRow}
    ${!canDrag ? styles.nonDraggableRow : ''}
    ${isDragging ? styles.draggingRow : ''}
  `;

  return (
    <tr ref={dragRef} className={rowClass}>
      {cells.map((cell, idx) => (
        <React.Fragment key={idx}>{cell}</React.Fragment>
      ))}
    </tr>
  );
};

// Create a custom icon type that accepts SVG props along with an optional title.
type IconComponent = React.FC<React.SVGProps<SVGSVGElement> & { title?: string }>;

// Cast the icons to the custom type.
const ValidatedFaLink = FaLink as unknown as IconComponent;
const ValidatedFaUnlink = FaUnlink as unknown as IconComponent;
const ValidatedFaUserShield = FaUserShield as unknown as IconComponent;
const ValidatedFaUser = FaUser as unknown as IconComponent;

// Main TableComponent
interface TableComponentProps {
  columns: Column<RowData>[]; // Columns for the table
  data: RowData[]; // Data for the table
  setData: React.Dispatch<React.SetStateAction<RowData[]>>; // Function to update table data
}

const TableComponent: React.FC<TableComponentProps> = ({ columns, data, setData }) => {
  // Function to remove a row by ID
  const removeRow = (id: string) => {
    setData((prevData) => prevData.filter((row) => row.id !== id));
  };

  // Define custom column cell renderers
  const processedColumns = useMemo(() => {
    return columns.map((col) => {
      if (col.accessor === 'associated') {
        return {
          ...col,
          Cell: ({ value }: { value: any }) => {
            const isAssociated = value === 'true' || value === true;
            const btnClass = `${buttonStyles.associateButton} ${
              isAssociated ? buttonStyles.associated : ''
            }`;
            return (
              <button className={btnClass}>
                {isAssociated ? (
                  <ValidatedFaLink aria-hidden="true" title={i18n.t('Associated')} />
                ) : (
                  <ValidatedFaUnlink aria-hidden="true" title={i18n.t('Not Associated')} />
                )}
              </button>
            );
          },
        };
      }

      if (col.accessor === 'isAdmin') {
        return {
          ...col,
          Cell: ({ value }: { value: any }) => {
            const isAdmin = value === 'true' || value === true;
            const btnClass = `${buttonStyles.associateButton} ${
              isAdmin ? buttonStyles.associated : ''
            }`;
            return (
              <button className={btnClass}>
                {isAdmin ? (
                  <ValidatedFaUserShield aria-hidden="true" title="Admin" />
                ) : (
                  <ValidatedFaUser aria-hidden="true" title="Non-Admin" />
                )}
              </button>
            );
          },
        };
      }

      return col;
    });
  }, [columns]);

  const memoizedData = useMemo(() => data, [data]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { globalFilter },
  } = useTable<RowData>(
    {
      columns: processedColumns,
      data: memoizedData,
    },
    useFilters,
    useGlobalFilter
  );

  return (
    <div className={styles.tableContainer}>
      <div className={styles.filterContainer}>
        {preGlobalFilteredRows && (
          <GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows as Row<RowData>[]}
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
          />
        )}
      </div>

      <div className={styles.tableWrapper}>
        <table {...getTableProps()} className={styles.styledTable}>
          <thead>
            {headerGroups.map((headerGroup: HeaderGroup<RowData>) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column: ColumnInstance<RowData>) => (
                  <th
                    {...column.getHeaderProps()}
                    className={`${styles.tableHeader} ${
                      column.id === 'id' ? styles.hiddenColumn : ''
                    }`}
                  >
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.length > 0 ? (
              rows.map((row) => {
                prepareRow(row);
                const cells = row.cells.map((cell) => (
                  <td
                    {...cell.getCellProps()}
                    className={`${styles.tableCell} ${
                      cell.column.id === 'id' ? styles.hiddenColumn : ''
                    }`}
                  >
                    {cell.render('Cell')}
                  </td>
                ));

                return (
                  <DraggableRow
                    key={row.getRowProps().key}
                    row={row}
                    cells={cells}
                    onDragEnd={removeRow}
                  />
                );
              })
            ) : (
              <tr>
                <td colSpan={processedColumns.length} className={styles.noData}>
                  <div className={styles.noDataContent}>
                    <span>😕</span>
                    <p>{i18n.t('No matching records found')}</p>
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TableComponent;
