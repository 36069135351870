import i18n from "../../i18n";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import {
    faHome,
    faSlidersH,
    faUserPen
} from "@fortawesome/free-solid-svg-icons";

export interface MenuItem {
    id: string; // Unique identifier required by react-arborist
    name: string;
    url?: string;
    adminRequired: boolean;
    superAdminRequired: boolean;
    icon?: IconDefinition;
    children?: MenuItem[];
    type: "header" | "footer" | "menuItem";
}

export const rawMenuItems: MenuItem[] = [
    {
        id: "header",
        type: "header",
        name: "SecuriSpot",
        adminRequired: false,
        superAdminRequired: false
    },
    {
        id: "home",
        name: i18n.t("Home"),
        url: "/supervision",
        adminRequired: false,
        icon: faHome as IconDefinition,
        superAdminRequired: false,
        type: "menuItem",
        children: [
            {
                id: "supervision",
                name: i18n.t("Supervision"),
                url: "/supervision",
                adminRequired: false,
                superAdminRequired: false,
                type: "menuItem"
            }
        ]
    },
    {
        id: "signup",
        name: i18n.t("Signup"),
        url: "/signup/",
        adminRequired: true,
        icon: faUserPen as IconDefinition,
        superAdminRequired: true,
        type: "menuItem",
        children: [
            {
                id: "create-user",
                name: i18n.t("CreateUser"),
                url: "/signup/user",
                adminRequired: true,
                superAdminRequired: false,
                type: "menuItem"
            }
        ]
    },
    {
        id: "global-view",
        name: i18n.t("GlobalView"),
        url: "/GlobalView/",
        adminRequired: true,
        icon: faSlidersH as IconDefinition,
        superAdminRequired: true,
        type: "menuItem",
        children: [
            {
                id: "global-view-child",
                name: i18n.t("GlobalView"),
                url: "/GlobalView",
                adminRequired: true,
                superAdminRequired: false,
                type: "menuItem"
            }
        ]
    },
    {
        id: "footer",
        type: "footer",
        name: "Version",
        adminRequired: false,
        superAdminRequired: false
    }
];