import React, { useState } from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { ToastProvider } from "react-toast-notifications";

import classes from "./App.module.css";
import Header from "./navigation/header/Header";
import Menu from "./navigation/menu/Menu";
import Mediaspots from "./admin/mediaspots/Mediaspots";
import DevicesMapping from "./devicesmapping/DevicesMapping";
import Home from "./home/Home";
import SignUp from "./SignUp/SignUp";
import GlobalViewComponent from "./GlobalView/globalviewcomponent";
import { withAuth } from "./hoc/withAuth";
import { DeviceMapProvider, useDeviceMap } from "./helpers/DeviceMapProvider"; // Ensure correct import
import Loader from "./component/Loader"; // Make sure you have this component

// Separate component that renders the app content once deviceMap is loaded
const AppContent: React.FC = () => {
  const [isMenuCollapsed, setIsMenuCollapsed] = useState(false);
  const { isLoading } = useDeviceMap(); // Retrieve loading state from context

  const handleMenuToggle = () => {
    setIsMenuCollapsed(!isMenuCollapsed);
  };

  // Show loader while deviceMap is loading
  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className={classes.App}>
      <BrowserRouter>
        <Header />
        <div className={classes.AppContent}>
          <Menu isCollapsed={isMenuCollapsed} toggleCollapse={handleMenuToggle} />
          <div
            className={`${classes.MainContent} ${
              isMenuCollapsed ? classes.MainContentCollapsed : classes.MainContentExpanded
            }`}
          >
            <ToastProvider>
              <Switch>
                <Redirect exact from="/" to="/supervision" />
                <Redirect exact from="/supervision" to="/supervision/global" />
                <Redirect exact from="/admin" to="/admin/devices" />
                <Redirect exact from="/signup" to="/signup/user" />

                <Route path="/supervision" render={() => <Home isMenuCollapsed={isMenuCollapsed} />} />
                <Route path="/admin/devices" render={() => <DevicesMapping />} />
                <Route path="/signup/user" render={() => <SignUp />} />
                <Route path="/globalview" render={() => <GlobalViewComponent />} />
              </Switch>
            </ToastProvider>
          </div>
        </div>
      </BrowserRouter>
    </div>
  );
};

const App: React.FC = () => {
  return (
    <DeviceMapProvider>
      <AppContent />
    </DeviceMapProvider>
  );
};

export default withAuth(App);
