import React, { createContext, useState, useEffect, useContext } from "react";
import { fetchDeviceMap } from "../helpers/MappingSecurispot"; // Adjust path if needed

// Define the shape of the context data
interface DeviceMapContextType {
  deviceMap: Record<string, string>;
  refreshDeviceMap: () => Promise<void>;
  isLoading: boolean;
}

// Create a context with default values
const DeviceMapContext = createContext<DeviceMapContextType>({
  deviceMap: {},
  refreshDeviceMap: async () => {},
  isLoading: true,
});

export let getLatestDeviceMap = (): Record<string, string> => ({});

export const DeviceMapProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [deviceMap, setDeviceMap] = useState<Record<string, string>>({});
  const [isLoading, setIsLoading] = useState(true); // ✅ Track loading state

  // Function to fetch and update deviceMap
  const refreshDeviceMap = async () => {
    setIsLoading(true); // ✅ Start loading
    const map = await fetchDeviceMap();
    if (map) {
      setDeviceMap(map);
      getLatestDeviceMap = () => map; // ✅ Ensure function always returns latest data
    }
    setIsLoading(false); // ✅ Stop loading once data is fetched
  };

  // Fetch device map on mount
  useEffect(() => {
    refreshDeviceMap();
  }, []);

  return (
    <DeviceMapContext.Provider value={{ deviceMap, refreshDeviceMap, isLoading }}>
      {children}
    </DeviceMapContext.Provider>
  );
};

// Hook to access deviceMap from React components
export const useDeviceMap = () => useContext(DeviceMapContext);
