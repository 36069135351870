import { useEffect } from 'react';

/**
 * useEscapeKey
 *
 * @param onEscape - A callback function to execute when Escape is pressed
 * @param isActive - Determines whether the hook should listen for Escape key events
 */
export function useEscapeKey(onEscape: () => void, isActive: boolean) {
  useEffect(() => {
    if (!isActive) return;

    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        onEscape();
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [onEscape, isActive]);
}
