import { useEffect, useState } from 'react';
import { ApiDataResponse } from '../../../api/Request';
import { GraphType } from '../../../graph/GraphTypes';
import { createHeatmap } from '../../../ai/pdf/createheatmap/createMatrixChart';
import { processApiResponseData } from '../../../ai/datahelpers/processApiResponseData';
import { calculateWeekNumber } from '../../../ai/pdf/createheatmap/dateshelpers/formatDate';

interface DataItem {
  rule: string;
  dates: string[];
  count: number;
  language: string;
}

/**
 * useHeatmapGeneration
 *
 * @param showAIModal - Whether the modal is currently shown
 * @param apiResponseData - The API data that will be transformed into heatmap data
 * @param graphType - Optional graph type used to process or generate the heatmap
 * @returns A string URL (base64 encoded image) for the heatmap, or null if not generated
 */
export function useHeatmapGeneration(
  showAIModal: boolean,
  apiResponseData: ApiDataResponse<any> | undefined,
  graphType?: GraphType
) {
  const [heatmapImage, setHeatmapImage] = useState<string | null>(null);

  useEffect(() => {
    const generateHeatmap = async () => {
      if (showAIModal && apiResponseData && Array.isArray(apiResponseData.data)) {
        try {
          // Process the API response data
          const aggregatedDataArray: DataItem[] | undefined = 
            await processApiResponseData(apiResponseData, graphType);

          if (!aggregatedDataArray) {
            console.error('aggregatedDataArray is undefined; check processApiResponseData');
            return;
          }

          if (aggregatedDataArray.length > 0) {
            // Determine the week number for the heatmap
            const firstDate = aggregatedDataArray[0].dates[0] || '';
            const weekNumber = calculateWeekNumber(firstDate);

            // Generate the heatmap image
            const image = await createHeatmap(aggregatedDataArray, weekNumber, false);
            setHeatmapImage(image);
          }
        } catch (error) {
          console.error('Error generating heatmap:', error);
        }
      } else {
        // Optionally clear the heatmap if modal closes or data is invalid
        setHeatmapImage(null);
      }
    };

    generateHeatmap();
  }, [showAIModal, apiResponseData, graphType]);

  return heatmapImage;
}
