import React from "react";
import classes from "./Loader.module.css"; // Optional styling
import i18n from "../i18n";
const Loader = () => {
  return (
    <div className={classes.LoaderContainer}>
      <div className={classes.Spinner}></div>
      <p>{i18n.t('AppLoader')}</p>
    </div>
  );
};

export default Loader;
