import { getSecurispotReferences } from "../api/OrganizationRequests";
import { getLatestDeviceMap } from "./DeviceMapProvider";
/**
 * The shape of the deviceMap the backend returns:
 * { deviceMap: { "1421022081340": "fp-securispot-003", ... } }
 */
interface DeviceMap {
  [serialNumber: string]: string;
}

/**
 * We'll keep our dynamically fetched deviceMap here in memory.
 * Initially empty.
 */
let deviceMap: DeviceMap = {};

/**
 * Actually make the POST request to your backend endpoint with empty payload.
 * The backend will respond with something like:
 * { deviceMap: { "1421022081340": "fp-securispot-003", ... } }
 */
export async function fetchDeviceMap(): Promise<Record<string, string> | undefined> {
  try {
    const response = await getSecurispotReferences();
    if (response && response.deviceMap) {
      return response.deviceMap;
    } else {
      console.warn("No deviceMap in backend response:", response);
      return undefined;
    }
  } catch (error) {
    console.error("Error fetching deviceMap:", error);
    return undefined;
  }
}

/**
 * Initialize the global 'deviceMap' by calling 'fetchDeviceMap()' and storing the result.
 * Call this once, e.g. at app startup or in a React useEffect.
 */
export async function initDeviceMap() {
  const map = await fetchDeviceMap();
  if (map) {
    deviceMap = map;
    console.log("DeviceMap updated:", deviceMap);
  }
}
/**
 * findHost uses the current deviceMap to look up a friendly name for the serial.
 * If the serial is undefined, return "Tous les securispots".
 * If not found in the map, return the serial itself.
 */
export function findHost(serial: string): string {
  const deviceMap = getLatestDeviceMap(); // ✅ Always get the latest deviceMap

  if (!serial) {
    return "Tous les securispots";
  }

  return deviceMap[serial] || serial; // ✅ Uses updated deviceMap from state
}